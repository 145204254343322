import React, { useState, useEffect, useRef, memo } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Modal from "react-modal";
import dayjs from "dayjs";
import { detect } from "detect-browser";

import { selectProducts } from "../../store/products/products.selector";
import ProductList from "../../components/product-list/product-list.component";
import {
  addToCart,
  clearCheckoutInfo,
  removeCheckoutExpiry,
  removeFromCart,
  setIsMenuOpen,
  setMessageShown,
  setOrderType,
} from "../../store/cart/cart.slice";
import {
  checkoutUrl,
  selectCartItems,
  selectCheckoutExpiry,
  selectIsMessageShown,
} from "../../store/cart/cart.selector";
import CartPage from "../cart-page/cart-page.page";
import ViewCart from "../../components/view-cart/view-cart";
import { fetchProductList } from "../../store/products/products.api";
import Footer from "../../components/footer/footer.component";
import { fetchRestaurantProfile } from "../../store/profile/profile.api";
import {
  selectProfileImage,
  selectProfileName,
} from "../../store/profile/profile.selector";
import MenuPage from "../menu-page/menu-page.page";
import { ReactComponent as TakeAwayIcon } from "../../assets/images/take-away-bag.svg";
import { ReactComponent as DineInIcon } from "../../assets/images/dine-in.svg";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Home = () => {
  const dispatch = useDispatch();
  const formRef = useRef<any>();
  const browser = detect();

  const [activeAccordion, setActiveAccordion] = useState<string | null>(null);
  const [restaurantId, setRestaurantId] = useState<string>("");
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [hasMessageShown, setHasMessageShown] = useState<boolean>(false);

  const productsList = useSelector(selectProducts, shallowEqual);
  const cartItems = useSelector(selectCartItems, shallowEqual);
  const isMessageShown = useSelector(selectIsMessageShown, shallowEqual);
  const restaurantName = useSelector(selectProfileName, shallowEqual);
  const restaurantLogo = useSelector(selectProfileImage, shallowEqual);
  const urlCheckout = useSelector(checkoutUrl, shallowEqual);
  const checkoutExpiry = useSelector(selectCheckoutExpiry, shallowEqual);

  const handleAccordionClick = (accordionId: any) => {
    setActiveAccordion((prevAccordion) =>
      prevAccordion === accordionId ? null : accordionId
    );
  };

  const onAddToCart = (product: any): void => {
    const updateProduct = { ...product, quantity: 1 };
    dispatch(addToCart({ product: updateProduct, cart: cartItems }));
  };

  const onRemoveFromCart = (product: any): void => {
    dispatch(removeFromCart({ product, cart: cartItems }));
  };

  const handleMenu = () => {
    dispatch(setIsMenuOpen());
  };

  const showAlert = () => {
    alert(
      "Scan via camera instead of iphone inbuilt scanner, if already using continue."
    );
    setHasMessageShown(true);
    setMessageShown(true)
    //dispatch(setMessageShown(true));
  };

  useEffect(() => {
    if (browser && browser.name === "ios" && browser.os === "iOS") {
      showAlert();
    }
  }, []);

  useEffect(() => {
    const pathname = window.location.pathname;
    const parts = pathname.split("/");
    const id = parts[parts.length - 1];
    if (id.length > 0) {
      setRestaurantId(id);
      dispatch(fetchProductList(id) as any);
      dispatch(fetchRestaurantProfile(id) as any);
    }
  }, [dispatch]);

  useEffect(() => {
    if (urlCheckout) {
      if (checkoutExpiry) {
        if (dayjs(new Date()).diff(checkoutExpiry, "minutes") < 0) {
          window.location.href = urlCheckout;
        } else {
          dispatch(clearCheckoutInfo());
          dispatch(removeCheckoutExpiry());
        }
      } else {
        // window.location.href = urlCheckout;
        // window.open(urlCheckout, "_blank");
        // submitCheckoutPage(urlCheckout);
      }
    }

    return () => {
      dispatch(clearCheckoutInfo());
      dispatch(removeCheckoutExpiry());
    };
  }, [urlCheckout, dispatch, checkoutExpiry]);

  useEffect(() => {
    if (formRef.current && urlCheckout) {
      formRef.current.submit();
    }
  }, [formRef, urlCheckout]);

  const handleOrderType = (type: string) => {
    dispatch(setOrderType(type));
    setIsOpen(false);
  };

  const handlePopup = () => {
    setIsOpen(true);
  };

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  if (productsList.length === 0) {
    return (
      <div className="w-full h-screen inline-flex items-center flex-col pt-10">
        <span className="text-3xl">Scan</span>
        <span className="text-lg text-gray-500">
          Scan the restaurant QR code to order
        </span>
        <img
          alt="deep-link-qr"
          src="assets/images/deep-link-qr.png"
          className="md:w-96 md:h-96 w-48 h-48 mt-10"
        />
      </div>
    );
  }

  return (
    <>
      <main>
        <CartPage handleCheckout={handlePopup} />
        <MenuPage restaurantId={restaurantId} />
        <div
          className="w-full h-10 inline-flex items-center justify-end"
          onClick={handleMenu}
        >
          <span className="text-primary underline mr-2 cursor-pointer">
            MENU
          </span>
        </div>
        <section className="w-full flex items-center mt-5 flex-col overflow-y-scroll mb-11">
          <div className="w-[80%] md:w-[50%] lg:w-[30%] inline-flex items-center spac">
            <img
              className="h-20 w-20 rounded-full"
              src={`${restaurantLogo}`}
              alt="restaurant"
            />
            <span className="text-lg font-semibold ml-3">{restaurantName}</span>
          </div>
          <div
            id="accordion-flush"
            data-accordion="collapse"
            className="w-[80%] md:w-[50%] lg:w-[30%]"
          >
            <ProductList
              addToCart={onAddToCart}
              removeFromCart={onRemoveFromCart}
              productsList={productsList}
              activeAccordion={activeAccordion}
              handleAccordionClick={handleAccordionClick}
              cartItems={cartItems}
            />
          </div>
        </section>
        <form method="POST" ref={formRef} action={urlCheckout} />
        <Modal isOpen={modalIsOpen} style={customStyles}>
          <div className="inline-flex flex-col items-center">
            <span>Where will you be eating today?</span>
            <div className="inline-flex flex-row justify-center my-2">
              <div
                className="h-28 w-28 border-[.1px] border-black mx-2 inline-flex flex-col justify-center items-center px-4 py-3"
                onClick={() => handleOrderType("Dine In")}
              >
                Dine In
                <DineInIcon />
              </div>
              <div
                className="h-28 w-28 border-[.1px] border-black mx-2 inline-flex flex-col justify-center items-center px-4 py-3"
                onClick={() => handleOrderType("Take Away")}
              >
                Take Away
                <TakeAwayIcon />
              </div>
            </div>
            <span
              className="underline cursor-pointer"
              onClick={handleClosePopup}
            >
              CANCEL
            </span>
          </div>
        </Modal>
      </main>
      <ViewCart />
    </>
  );
};

export default memo(Home);
